import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Preloader from "../components/blocks/Preloader";
import AdminBlogs from "../pages/admin/admin_blog/AdminBlogs";
import AdminCareers from "../pages/admin/admin_careers/AdminCareers";
import AdminCourses from "../pages/admin/admin_courses/AdminCourses";
import AdminEvents from "../pages/admin/admin_events/AdminEvents";
import AdminStories from "../pages/admin/admin_stories/AdminStories";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import ForgotPassword from "../pages/admin/forgot_password/ForgotPassword";
import ResetPassword from "../pages/admin/forgot_password/ResetPassword";
import Login from "../pages/admin/login/Login";
import Register from "../pages/admin/register/Register";
import AffiliatesKids from "../pages/affiliates/AffiliatesKids";
import BlogDetails from "../pages/blog/BlogDetails";
import ContactUs from "../pages/contact_us/ContactUs";
import Nucleus from "../pages/nucleus/Nucleus";
import PrivacyPolicy from "../pages/privacy_policy/PrivacyPolicy";
import RefundPolicy from "../pages/privacy_policy/RefundPolicy";
import TermsAndConditions from "../pages/terms_and_condition/TermsAndConditions";

const Faqs = React.lazy(() => import("../pages/faqs/Faqs"));
const FaqDetails = React.lazy(() => import("../pages/faqs/FaqDetails"));

const AboutUs = React.lazy(() => import("../pages/about_us/AboutUs"));
const CorporateTraining = React.lazy(() =>
  import("../pages/corporate_training/CorporateTraining")
);
const Events = React.lazy(() => import("../pages/events/Events"));
const EventDetails = React.lazy(() => import("../pages/events/EventDetails"));

const Blog = React.lazy(() => import("../pages/blog/Blog"));

const Stories = React.lazy(() => import("../pages/stories/Stories"));

const StudentLoan = React.lazy(() =>
  import("../pages/student_loan/StudentLoan")
);

const Hire = React.lazy(() => import("../pages/hire/Hire"));
const Careers = React.lazy(() => import("../pages/careers/Careers"));
const Jobs = React.lazy(() => import("../pages/jobs/Jobs.js"));
const BeACoach = React.lazy(() => import("../pages/be_a_coach/BeACoach"));
const UtivaBusinessNetwork = React.lazy(() =>
  import("../pages/ubn/UtivaBusinessNetwork")
);

const Error404 = React.lazy(() => import("../pages/Error404"));

// Schools
// const AllSchools = React.lazy(() => import("../pages/schools/AllSchools"));
const School = React.lazy(() => import("../pages/schools/School"));

const AllSchoolsPayfi = React.lazy(() =>
  import("../pages/schools_payfi/AllSchoolsPayfi")
);

// Courses
const Course = React.lazy(() => import("../pages/courses/Course"));
const DSFRegistration = React.lazy(() =>
  import("../pages/courses/DSFRegistration")
);

// Ubuntu
const Ubuntu = React.lazy(() => import("../pages/ubuntu/Ubuntu"));

// Alumni
const Alumni = React.lazy(() => import("../pages/alumni/Alumni"));

const Affiliates = React.lazy(() => import("../pages/affiliates/Affiliates"));

const Masterclass = React.lazy(() =>
  import("../pages/masterclass/Masterclass")
);

const MasterclassA = React.lazy(() =>
  import("../pages/masterclass-a/MasterclassA")
);

const Dev = React.lazy(() => import("../pages/dev/Dev.js"));

// Version 2.0
const Home = React.lazy(() => import("../pages/home/Home"));

const Individual = React.lazy(() => import("../pages/individual/Individual"));

const Corpers = React.lazy(() => import("../pages/corpers/Corpers"));
const Partners = React.lazy(() => import("../pages/partners/Partners"));
const Apprenticeship = React.lazy(() =>
  import("../pages/apprenticeship/Apprenticeship")
);
const Business = React.lazy(() => import("../pages/business/Business"));
const BusinessCourses = React.lazy(() => import("../pages/business/Courses"));

const Binance = React.lazy(() => import("../pages/binance/Binance"));
const Binancecharity = React.lazy(() =>
  import("../pages/binancecharity/Binancecharity")
);

const HFM = React.lazy(() => import("../pages/hfm/Hfm"));

const Podcast = React.lazy(() => import("../pages/podcast/Podcast"));

const Migrate = React.lazy(() => import("../pages/migrate/Migrate.js"));

// Influencers
const InfluencerPraiz = React.lazy(() => import("../pages/influencers/praiz"));
const InfluencerKagan = React.lazy(() => import("../pages/influencers/kagan"));
const InfluencerOlusola = React.lazy(() =>
  import("../pages/influencers/olusola")
);
const InfluencerDecoTechie = React.lazy(() =>
  import("../pages/influencers/decotechie")
);

const InfluencerAbbey = React.lazy(() => import("../pages/influencers/abbey"));

const TestPage = React.lazy(() => import("../pages/test/Test.js"));
const Talk2EPage = React.lazy(() => import("../pages/talk2E/Talk2E.js"));
const AlumniStoriesPage = React.lazy(() =>
  import("../pages/alumni_stories/AlumniStories.js")
);

const NyscApprenticeship = React.lazy(() =>
  import("../pages/nysc_apprenticeship/NyscApprenticeship.js")
);

const Ibadan1 = React.lazy(() => import("../pages/ibadan1/Ibadan1.js"));
const HRScholarship = React.lazy(() =>
  import("../pages/hrscholarship/HRScholarship.js")
);

const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Preloader />}>
        <Switch>
          {/* VERSION 2.0 */}
          <Route exact path="/" component={Home} />

          <Route exact path="/payfi-enrol" component={AllSchoolsPayfi} />

          <Route exact path="/dsf-registration" component={DSFRegistration} />

          <Route exact path="/binance" component={Binance} />
          <Route exact path="/binancecharity" component={Binancecharity} />
          <Route exact path="/scholarship/hfm" component={HFM} />

          {/* Grow with Utiva */}
          <Route exact path="/get-started" component={Individual} />
          <Route exact path="/business" component={Business} />
          <Route exact path="/business/courses" component={BusinessCourses} />
          <Route exact path="/nucleus" component={Nucleus} />
          <Route exact path="/ubuntu" component={Ubuntu} />
          <Route exact path="/hire" component={Hire} />

          {/* Schools */}
          <Route exact path="/school" component={Individual} />
          <Route exact path="/global" component={Individual} />
          <Route exact path="/start" component={Individual} />
          <Route exact path="/school/:slug" component={School} />

          {/* Courses */}
          <Route exact path="/courses/:slug" component={Course} />

          {/* Company */}
          <Route exact path="/about-us" component={AboutUs} />
          {/* <Route exact path="/affiliate" component={Affiliates} /> */}
          <Route exact path="/champions" component={Affiliates} />
          <Route exact path="/affiliate-kids" component={AffiliatesKids} />
          <Route exact path="/alumni" component={Alumni} />
          <Route exact path="/employabilityprogram" component={Alumni} />
          <Route exact path="/be-a-trainer" component={BeACoach} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/jobs" component={Jobs} />
          <Route exact path="/ubn" component={UtivaBusinessNetwork} />

          {/* Resources */}
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:slug" component={BlogDetails} />
          <Route exact path="/faqs" component={Faqs} />
          <Route exact path="/faqs/:slug" component={FaqDetails} />
          <Route exact path="/corpers" component={Corpers} />
          <Route exact path="/partners" component={Partners} />
          <Route exact path="/apprenticeship" component={Apprenticeship} />
          <Route
            exact
            path="/nysc-apprenticeship"
            component={NyscApprenticeship}
          />
          <Route exact path="/nysc" component={NyscApprenticeship} />
          <Route exact path="/stories" component={Stories} />
          <Route exact path="/scholarship" component={StudentLoan} />
          <Route
            exact
            path="/corporate-training"
            component={CorporateTraining}
          />
          <Route exact path="/mc" component={Masterclass} />
          <Route exact path="/mci" component={Masterclass} />
          <Route exact path="/mca" component={MasterclassA} />
          <Route exact path="/ibadan1" component={Ibadan1} />
          <Route exact path="/hrscholarship" component={HRScholarship} />
          <Route exact path="/ev" component={Dev} />

          <Route exact path="/events" component={Events} />
          <Route exact path="/events/:slug" component={EventDetails} />

          {/* Policies */}
          <Route exact path="/terms" component={TermsAndConditions} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/refund-policy" component={RefundPolicy} />

          {/* Contact */}
          <Route exact path="/contact-us" component={ContactUs} />

          {/* Podcast */}
          <Route exact path="/podcast" component={Podcast} />

          {/* Migrate */}
          <Route exact path="/migrate" component={Migrate} />

          {/* Alumni Stories */}
          <Route exact path="/alumni-stories" component={AlumniStoriesPage} />

          {/* Influencers */}
          <Route exact path="/praiz" component={InfluencerPraiz} />
          <Route exact path="/kagan" component={InfluencerKagan} />
          <Route exact path="/olusola" component={InfluencerOlusola} />
          <Route exact path="/decotechie" component={InfluencerDecoTechie} />
          <Route exact path="/abbey" component={InfluencerAbbey} />

          <Route exact path="/test" component={TestPage} />
          <Route exact path="/talk2E" component={Talk2EPage} />

          <Route exact path="/support" component={Talk2EPage} />

          {/* Admin */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/dashboard/blogs" component={AdminBlogs} />
          <Route exact path="/dashboard/careers" component={AdminCareers} />
          <Route exact path="/dashboard/events" component={AdminEvents} />
          <Route exact path="/dashboard/stories" component={AdminStories} />
          <Route exact path="/dashboard/courses" component={AdminCourses} />

          <Route component={Error404} />
          <Route exact path="/loader" component={Preloader} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default MainRoutes;
